import React, {useState} from 'react';
import { Popup, TextField } from '@partssourceinc/react-ui-core';
import axios from 'axios';
import {validateUPSAccountNumber, validateFedExAccountNumber} from 'utility';

const CustomerAdminAddUserDialog = ({onCancel, onConfirm, location}) => {
    const [fedexAccount, setFedExAccount] = useState(location.fedexAccount);
    const [fedexAccountValid, setFedExAccountValid] = useState(validateFedExAccountNumber(location.fedexAccount));
    const [upsAccount, setUpsAccount] = useState(location.upsAccount);
    const [upsAccountValid, setUpsAccountValid] = useState(validateUPSAccountNumber(location.upsAccount));
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    const onSave = () => {
        if ((!fedexAccountValid && !upsAccountValid) || (!fedexAccountValid && fedexAccount) || (!upsAccountValid && upsAccount)) {
            setShowError(true);
            return;
        }

        let obj = {
            FedexAccount: fedexAccount,
            UpsAccount: upsAccount,
        } 

        setIsLoading(true);
        axios.put(`ShoppingService/api/v1/customerAdmin/location/${location.id}/shippingAccounts`, obj).then((response) => {
            setIsLoading(false);
            onConfirm({fedexAccount: fedexAccount, upsAccount: upsAccount});
        });
    }

    const onFedexAccountChange = (e) => {
        setFedExAccount(e.target.value);
        setFedExAccountValid(validateFedExAccountNumber(e.target.value));
    }

    const onUpsAccountChange = (e) => {
        setUpsAccount(e.target.value);
        setUpsAccountValid(validateUPSAccountNumber(e.target.value));
    }

    return (
        <React.Fragment>
            <Popup
                confirmText="Save"
                cancelText="Cancel"
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={onSave}
                loading={isLoading}
                disableConfirm={!fedexAccount && !upsAccount}
            >
                <h1 className="title">Shipping Accounts</h1>
                <TextField
                    type="text"
                    autoFocus={true}
                    id="fedexAccount"
                    tabIndex="1"
                    className="_field"
                    maxLength={15}
                    value={fedexAccount}
                    text={fedexAccount}
                    showErrorMessage={showError && fedexAccount && !fedexAccountValid}
                    onChange={onFedexAccountChange}
                    placeholder="FedEx Account"
                    label="FedEx Account"
                />
                <TextField
                    type="text"
                    id="ups"
                    tabIndex="2"
                    className="_field"
                    maxLength={15}
                    value={upsAccount}
                    text={upsAccount}
                    showErrorMessage={showError && upsAccount && !upsAccountValid}
                    onChange={onUpsAccountChange}
                    placeholder="UPS Account"
                    label="UPS Account"
                />
            </Popup>
        </React.Fragment>
    );
}

export default CustomerAdminAddUserDialog;
