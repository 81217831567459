import React, {useState, useEffect} from 'react';
import { Popup, TextField } from '@partssourceinc/react-ui-core';
import {stripAwayPhoneNumberSymbols} from 'utility';
import Select from 'react-select';
import axios from 'axios';
import states from 'data/States';
import {RegistrationAddressDialog} from 'pages/Registration/RegistrationAddressDialog';

const CustomerAdminAddAddressDialog = ({onCancel, onConfirm, address, locationId, addressType}) => {

    const [showErrors, setShowErrors] = useState(false);
    const [addressName, setAddressName] = useState(address ? address.addressName : '');
    const [address1, setAddress1] = useState(address ? address.address1 : '');
    const [address2, setAddress2] = useState(address ? address.address2 : '');
    const [zip, setZip] = useState(address ? address.zip : '');
    const [city, setCity] = useState(address ? address.city : '');
    const [validZipCode, setValidZipCode] = useState(address ? true : false);

    const countries = [
        {id: 'US', name: 'United States'},
        {id: 'CA', name: 'Canada'},
    ];

    let tempCountry = {id: 'US', name: 'United States'};
    
    if (address)
        tempCountry = countries.find((x) => x.id == address.country);

    const [selectedCountry, setSelectedCountry] = useState(tempCountry);

    let tempState = null;

    if (address)
        tempState = tempCountry && tempCountry.id === 'US' ? states.US.find((x) => x.value == address.state) : states.CA.find((x) => x.value == address.state)

    const [selectedState, setSelectedState] = useState(tempState ? tempState : null);

    const [isLoading, setIsLoading] = useState(false);

    const [showAddressDialog, setShowAddressDialog] = useState(false);
    const [originalAddressLabel, setOriginalAddressLabel] = useState('');
    const [selectedAddressOption, setSelectedAddressOption] = useState('');
    const [suggestedAddressLabel, setSuggestedAddressLabel] = useState('');
    const [suggestedAddress, setSuggestedAddress] = useState('');
    const [isAddressValid, setIsAddressValid] = useState(false);

    useEffect(() => {
    }, []);

    const onZipCodeChange = (e) => {
        setZip(e.target.value);
        setValidZipCode(isValidZipCode(e.target.value));
    };

    const isValidZipCode = (pZip) => {
        if (selectedCountry.value === 'US')
            return /^[0-9]{5}(?:-[0-9]{4})?$/i.test(pZip) && pZip.length <= 18;
        return /^[A-Za-z][0-9][A-Za-z][ ][0-9A-Za-z]{3}?$/i.test(pZip) && pZip.length <= 18;
    };

    const onStateChange = (value) => {
        setSelectedState(value)
    }

    const onCountryChange = (value) => {
        setSelectedCountry(value)
    }

    const onSave = () => {
        if (!addressName || !address1 || !zip || !city || !selectedState) {
            setShowErrors(true);
            return;
        }

        let request = {
            street1: address1,
            street2: address2,
            postalCode: zip,
            city: city,
            state: selectedState.value,
            country: selectedCountry.id,
        }
        setIsLoading(true);
        axios
            .post('/ShoppingService/api/v1/company/address/validate', request)
            .then((result) => {
                setIsLoading(false);
                let a = result.data;
                if (a.isValid && a.isOriginalAddressValid) {
                    let obj = {
                        id: address ? address.id : null,
                        addressName: addressName,
                        addressTypeId: address ? address.addressTypeId : addressType,
                        address1: address1,
                        address2: address2,
                        zip: zip,
                        city: city,
                        state: selectedState.value,
                        country: selectedCountry.id,
                    };

                    finish(obj);
                } else if (!a.isOriginalAddressValid && a.alternateAddress) {

                    let originalAddressLabelTemp = `<div>${address1}</div>`;
                    if (address2) originalAddressLabelTemp += `<div>${address2}</div>`;
                    originalAddressLabelTemp += `<div>${city}, ${selectedState.value} ${zip}</div>`;
                    originalAddressLabelTemp += `<div>${selectedCountry.id}</div>`;

                    setOriginalAddressLabel(originalAddressLabelTemp);
                    setSuggestedAddress(a.alternateAddress);

                    let suggestedAddressLabelTemp;
                    suggestedAddressLabelTemp = `<div>${a.alternateAddress.street1}</div>`;
                    if (suggestedAddressLabelTemp.address2)
                        suggestedAddressLabelTemp += `<div>${ a.alternateAddress.street2}</div>`;
                    suggestedAddressLabelTemp += `<div>${a.alternateAddress.city}, ${a.alternateAddress.state} ${a.alternateAddress.postalCode}</div>`;
                    suggestedAddressLabelTemp += `<div>${a.alternateAddress.country}</div>`;
                
                    setSuggestedAddressLabel(suggestedAddressLabelTemp);

                    setSelectedAddressOption('1');
                    setShowAddressDialog(true);   
                } else {
                    let originalAddressLabelTemp = `<div>${address1}</div>`;
                    if (address2) originalAddressLabelTemp += `<div>${address2}</div>`;
                    originalAddressLabelTemp += `<div>${city}, ${selectedState.value} ${zip}</div>`;
                    originalAddressLabelTemp += `<div>${selectedCountry.id}</div>`;

                    setOriginalAddressLabel(originalAddressLabelTemp);
                    setSelectedAddressOption('1');
                    setShowAddressDialog(true);
                }
            }).catch(() => {
                setSelectedAddressOption('1');
                setShowAddressDialog(true);
            })
    }

    const onAddressCheck = (e) => {
        setSelectedAddressOption(e.target.value);
    }

    const onAddressSave = () => {
        let obj;
        if (selectedAddressOption == '2')
            obj = {
                id: address ? address.id : null,
                addressName: addressName,
                addressTypeId: address ? address.addressTypeId : addressType,
                address1: suggestedAddress.street1,
                address2: suggestedAddress.street2,
                zip: suggestedAddress.postalCode,
                city: suggestedAddress.city,
                state: suggestedAddress.state,
                country: suggestedAddress.country,
            };
        else
            obj = {
                id: address ? address.id : null,
                addressName: addressName,
                addressTypeId: address ? address.addressTypeId : addressType,
                address1: address1,
                address2: address2,
                zip: zip,
                city: city,
                state: selectedState.value,
                country: selectedCountry.id,
            }

        finish(obj);
    }

    const onAdressDialogClose = () => {
        setShowAddressDialog(false);
    }

    const finish = (obj) => {
        onConfirm(obj);
    }

    return (
        <React.Fragment>
            <Popup
                confirmText={address ? 'Edit Address' : 'Add Address'}
                cancelText="Cancel"
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={onSave}
                loading={isLoading}
            >
                <h1 className="title"> { address ? 'Edit Address' : 'Add Address' }</h1>
                <TextField
                    type="text"
                    autoFocus={true}
                    id="name"
                    tabIndex="20"
                    className="_field"
                    maxLength={35}
                    value={addressName}
                    text={addressName}
                    showErrorMessage={showErrors && !addressName}
                    onChange={(e) => setAddressName(e.target.value)}
                    placeholder="Address Name"
                    label="Name"
                />
                <TextField
                    type="text"
                    tabIndex="21"
                    id="address1"
                    className="_field"
                    maxLength={35}
                    value={address1}
                    text={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    showErrorMessage={showErrors && !address1}
                    placeholder="Address Line 1"
                    label="Address Line 1"
                />
                <TextField
                    type="text"
                    id="address2"
                    tabIndex="22"
                    maxLength={35}
                    value={address2}
                    text={address2}
                    showErrorMessage={false}
                    onChange={(e) => {
                        setAddress2(e.target.value) 
                    }}
                    placeholder="Address Line 2 (optional)"
                    label="Address Line 2 (optional)"
                />
                <TextField
                    id="city"
                    value={city}
                    type="text"
                    tabIndex="23"
                    maxLength={35}
                    text={city}
                    showErrorMessage={showErrors && !city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="City"
                    label="City"
                />
                <Select tabIndex="24" isMulti={false} cacheOptions={true} options={selectedCountry.id === 'US' ? states.US : states.CA} getOptionValue={option => option.value}
                    getOptionLabel={option => option.text} noOptionsMessage={() => null} placeholder="State" isClearable={true}
                    classNamePrefix="select" value={selectedState} onChange={onStateChange} className={showErrors && !selectedState ? 'custom-multi-select error' : 'custom-multi-select'} />
                <TextField
                    id="zip"
                    value={zip}
                    type="text"
                    tabIndex="25"
                    maxLength={17}
                    text={zip}
                    style={{width: 160}}
                    showErrorMessage={showErrors && !validZipCode}
                    onChange={onZipCodeChange}
                    placeholder="Zip Code"
                    label="Zip Code"
                />
                
                <Select tabIndex="26" isMulti={false} cacheOptions={true} options={countries} getOptionValue={option => option.id}
                    getOptionLabel={option => option.name} noOptionsMessage={() => null} placeholder="Country" isClearable={true}
                    classNamePrefix="select" value={selectedCountry} onChange={onCountryChange} className={showErrors && !selectedCountry ? 'custom-multi-select error' : 'custom-multi-select'} />
            </Popup>
            {showAddressDialog && (
                <RegistrationAddressDialog
                    originalAddressLabel={originalAddressLabel}
                    suggestedAddressLabel={suggestedAddressLabel}
                    suggestedAddress={suggestedAddress}
                    originalChecked={selectedAddressOption == '1'}
                    validating={isAddressValid}
                    handleOnChange={onAddressCheck}
                    handleOnSave={onAddressSave}
                    handleOnClose={onAdressDialogClose}
                />
            )}
        </React.Fragment>

    );

}

export default CustomerAdminAddAddressDialog;
