import React, {useState, useEffect} from 'react';
import { Popup, TextField } from '@partssourceinc/react-ui-core';
import {stripAwayPhoneNumberSymbols} from 'utility';
import Select from 'react-select';
import axios from 'axios';
import states from 'data/States';
import {RegistrationAddressDialog} from '../Registration/RegistrationAddressDialog';

const CustomerAdminChangeLocationInfoDialog = ({onCancel, onConfirm, location}) => {

    const [showErrors, setShowErrors] = useState(false);
    const [locationName, setLocationName] = useState(location.name);
    const [addressName, setAddressName] = useState(location.addressName);
    const [address1, setAddress1] = useState(location.address1);
    const [address2, setAddress2] = useState(location.address2);

    const countries = [
        {id: 'US', name: 'United States'},
        {id: 'CA', name: 'Canada'},
    ];

    let tempCountry = countries.find((x) => x.id == location.country) ? countries.find((x) => x.id == location.country) : countries[0];

    const [selectedCountry, setSelectedCountry] = useState(tempCountry);
    const [zip, setZip] = useState(location.zip);
    const [validZipCode, setValidZipCode] = useState(true);
    const [city, setCity] = useState(location.city);

    let tempState = tempCountry && tempCountry.id === 'US' ? states.US.find((x) => x.value == location.state) : states.CA.find((x) => x.value == location.state)

    const [selectedState, setSelectedState] = useState(tempState);

    const [isLoading, setIsLoading] = useState(false);

    const [showAddressDialog, setShowAddressDialog] = useState(false);
    const [originalAddressLabel, setOriginalAddressLabel] = useState('');
    const [selectedAddressOption, setSelectedAddressOption] = useState('');
    const [suggestedAddressLabel, setSuggestedAddressLabel] = useState('');
    const [suggestedAddress, setSuggestedAddress] = useState('');
    const [isAddressValid, setIsAddressValid] = useState(false);

    useEffect(() => {
    }, []);

    const onZipCodeChange = (e) => {
        setZip(e.target.value);
        setValidZipCode(isValidZipCode(e.target.value));
    };

    const isValidZipCode = (pZip) => {
        if (selectedCountry.value === 'US')
            return /^[0-9]{5}(?:-[0-9]{4})?$/i.test(pZip) && pZip.length <= 18;
        return /^[A-Za-z][0-9][A-Za-z][ ][0-9A-Za-z]{3}?$/i.test(pZip) && pZip.length <= 18;
    };

    const onStateChange = (value) => {
        setSelectedState(value)
    }

    const onCountryChange = (value) => {
        setSelectedCountry(value)
    }

    const onSave = () => {
        if (!locationName || !address1 || !zip || !city || !selectedState) {
            setShowErrors(true);
            return;
        }

        let request = {
            street1: address1,
            street2: address2,
            postalCode: zip,
            city: city,
            state: selectedState.value,
            country: selectedCountry.id,
        };

        setIsLoading(true);
        axios
            .post('/ShoppingService/api/v1/company/address/validate', request)
            .then((result) => {
                setIsLoading(false);
                let a = result.data;
                if (a.isValid && a.isOriginalAddressValid) {
                    let obj = {
                        Id: location.id,
                        Name: locationName,
                        AddressId: location.addressId,
                        AddressName: addressName,
                        Address1: address1,
                        Address2: address2,
                        Zip: zip,
                        City: city,
                        State: selectedState.value,
                        Country: selectedCountry.id,
                    };

                    saveLocationInfo(obj);
                } else if (!a.isOriginalAddressValid && a.alternateAddress) {

                    let originalAddressLabelTemp = `<div>${address1}</div>`;
                    if (address2) originalAddressLabelTemp += `<div>${address2}</div>`;
                    originalAddressLabelTemp += `<div>${city}, ${selectedState.value} ${zip}</div>`;
                    originalAddressLabelTemp += `<div>${selectedCountry.id}</div>`;

                    setOriginalAddressLabel(originalAddressLabelTemp);
                    setSuggestedAddress(a.alternateAddress);

                    let suggestedAddressLabelTemp;
                    suggestedAddressLabelTemp = `<div>${a.alternateAddress.street1}</div>`;
                    if (suggestedAddressLabelTemp.address2)
                        suggestedAddressLabelTemp += `<div>${ a.alternateAddress.street2}</div>`;
                    suggestedAddressLabelTemp += `<div>${a.alternateAddress.city}, ${a.alternateAddress.state} ${a.alternateAddress.postalCode}</div>`;
                    suggestedAddressLabelTemp += `<div>${a.alternateAddress.country}</div>`;
                
                    setSuggestedAddressLabel(suggestedAddressLabelTemp);

                    setSelectedAddressOption('1');
                    setShowAddressDialog(true);   
                } else {
                    let originalAddressLabelTemp = `<div>${address1}</div>`;
                    if (address2) originalAddressLabelTemp += `<div>${address2}</div>`;
                    originalAddressLabelTemp += `<div>${city}, ${selectedState.value} ${zip}</div>`;
                    originalAddressLabelTemp += `<div>${selectedCountry.id}</div>`;

                    setOriginalAddressLabel(originalAddressLabelTemp);
                    setSelectedAddressOption('1');
                    setShowAddressDialog(true);
                }
            }).catch(() => {
                setSelectedAddressOption('1');
                setShowAddressDialog(true);
            })
    }

    const onAddressCheck = (e) => {
        setSelectedAddressOption(e.target.value);
    }

    const onAddressSave = () => {
        let obj;

        if (selectedAddressOption == '2')
            obj = {
                Id: location.id,
                Name: locationName,
                AddressId: location.addressId,
                AddressName: addressName,
                Address1: suggestedAddress.street1,
                Address2: suggestedAddress.street2,
                Zip: suggestedAddress.postalCode,
                City: suggestedAddress.city,
                State: suggestedAddress.state,
                Country: suggestedAddress.country,
            };
        else
            obj = {
                Id: location.id,
                Name: locationName,
                AddressId: location.addressId,
                AddressName: addressName,
                Address1: address1,
                Address2: address2,
                Zip: zip,
                City: city,
                State: selectedState.value,
                Country: selectedCountry.id,
            }

        saveLocationInfo(obj);
    }

    const onAdressDialogClose = () => {
        setShowAddressDialog(false);
    }

    const saveLocationInfo = (request) => {
        setIsLoading(true);
        axios.put(`ShoppingService/api/v1/customerAdmin/location/info`, request).then((response) => {
            request.addressId = response.data.addressId;
            onConfirm(request);
            setIsLoading(false);
        });
    }

    return (
        <React.Fragment>
            <Popup
                confirmText="Save"
                cancelText="Cancel"
                show={true}
                hideButtons={false}
                onCancel={onCancel}
                onConfirm={onSave}
                loading={isLoading}
            >
                <h1 className="title">Location Information</h1>
                <TextField
                    type="text"
                    autoFocus={true}
                    id="locationName"
                    tabIndex="1"
                    className="_field"
                    maxLength={50}
                    value={locationName}
                    text={locationName}
                    showErrorMessage={showErrors && !locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    placeholder="Location Name"
                    label="Location Name"
                />

                <TextField
                    type="text"
                    autoFocus={true}
                    id="addressName"
                    tabIndex="1"
                    className="_field"
                    maxLength={35}
                    value={addressName}
                    text={addressName}
                    showErrorMessage={showErrors && !addressName}
                    onChange={(e) => setAddressName(e.target.value)}
                    placeholder="Address Name"
                    label="Name"
                />
                <TextField
                    type="text"
                    tabIndex="2"
                    id="address1"
                    className="_field"
                    maxLength={35}
                    value={address1}
                    text={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                    showErrorMessage={showErrors && !address1}
                    placeholder="Address Line 1"
                    label="Address Line 1"
                />
                <TextField
                    type="text"
                    id="address2"
                    tabIndex="3"
                    maxLength={35}
                    value={address2}
                    text={address2}
                    showErrorMessage={false}
                    onChange={(e) => {
                        setAddress2(e.target.value) 
                    }}
                    placeholder="Address Line 2 (optional)"
                    label="Address Line 2 (optional)"
                />
                <TextField
                    id="city"
                    value={city}
                    type="text"
                    tabIndex="4"
                    maxLength={35}
                    text={city}
                    showErrorMessage={showErrors && !city}
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="City"
                    label="City"
                />
                <Select isMulti={false} cacheOptions={true} options={selectedCountry.id === 'US' ? states.US : states.CA} getOptionValue={option => option.value}
                    getOptionLabel={option => option.text} noOptionsMessage={() => null} placeholder="State" isClearable={true}
                    classNamePrefix="select" value={selectedState} onChange={onStateChange} className={showErrors && !selectedState ? 'custom-multi-select error' : 'custom-multi-select'} />
                <TextField
                    id="zip"
                    value={zip}
                    type="text"
                    tabIndex="8"
                    maxLength={17}
                    text={zip}
                    style={{width: 160}}
                    showErrorMessage={showErrors && !validZipCode}
                    onChange={onZipCodeChange}
                    placeholder="Zip Code"
                    label="Zip Code"
                />
                <Select isMulti={false} cacheOptions={true} options={countries} getOptionValue={option => option.id}
                    getOptionLabel={option => option.name} noOptionsMessage={() => null} placeholder="Country" isClearable={true}
                    classNamePrefix="select" value={selectedCountry} onChange={onCountryChange} className={showErrors && !selectedCountry ? 'custom-multi-select error' : 'custom-multi-select'} />
            </Popup>
            {showAddressDialog && (
                <RegistrationAddressDialog
                    originalAddressLabel={originalAddressLabel}
                    suggestedAddressLabel={suggestedAddressLabel}
                    suggestedAddress={suggestedAddress}
                    originalChecked={selectedAddressOption == '1'}
                    validating={isAddressValid}
                    handleOnChange={onAddressCheck}
                    handleOnSave={onAddressSave}
                    handleOnClose={onAdressDialogClose}
                />
            )}
        </React.Fragment>

    );

}

export default CustomerAdminChangeLocationInfoDialog;
