import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import PageMetaData from 'components/PageMetaData';
import {Loader} from '@partssourceinc/react-ui-core';
import styled from 'styled-components';
import axios from 'axios';
import {getFormattedPhoneNumber, logEvent} from 'utility';
import moment from 'moment';
import BreadCrumbs from 'components/BreadCrumbs';
import CustomerAdminChangeLocationInfoDialog from './CustomerAdminChangeLocationInfoDialog';
import CustomerAdminInformDialog from './CustomerAdminInformDialog';
import CustomerAdminAddAddressDialog from './CustomerAdminAddAddressDialog';
import CustomerAdminAddCarrierDialog from './CustomerAdminAddCarrierDialog';
import './customer-admin-user-details.less';

const LoaderContainer = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default function CustomerAdminLocationDetails() {
    const {locationId} = useParams();
    const history = useHistory();

    const [location, setLocation] = useState(null);

    const [loading, setLoading] = useState(true);
    const [loadingAction, setLoadingAction] = useState(false);
    const [showAllShippingAddresses, setShowAllShippingAddresses] = useState(false);
    const [showAllBillingAddresses, setShowAllBillingAddresses] = useState(false);
    const [showAllUsers, setShowAllUsers] = useState(false);
    const [showLocationInfromationDialog, setShowLocationInformationDialog] = useState(false);
    const [showCarrierAccountsDialog, setShowCarrierAccountsDialog] = useState(false);
    const [showAddAddressDialog, setShowAddAddressDialog] = useState(false);
    const [showUserInformDialog, setShowUserInformDialog] = useState(false);

    const [currentAddress, setCurrentAddress] = useState(null);
    const [currentAddressType, setCurrentAddressType] = useState(null);

    const [confirmTitle, setConfirmTitle] = useState('');
    const [confirmMsg, setConfirmMsg] = useState('');
    const [confirmAction, setConfrimAction] = useState(null);

    const customerAdminPermission = useSelector(state => state.user.settings.customerAdminPermission);

    const havePermissionForAddress = customerAdminPermission.permissions.find((x) => x == 4) != null;

    useEffect(() => {
        loadLocationDetails();
    }, []);

    const loadLocationDetails = () => {
        axios.get(`ShoppingService/api/v1/customerAdmin/location/${locationId}`).then((response) => {
            setLocation(response.data);
            setLoading(false);
        });
    };

    const onLocationInforamtionConfirm = (response) => {
        let tempLocation = {... location};

        tempLocation.addressId = response.addressId;
        tempLocation.name = response.Name;
        tempLocation.addressName = response.AddressName;
        tempLocation.address1 = response.Address1;
        tempLocation.address2 = response.Address2;
        tempLocation.city = response.City;
        tempLocation.zip = response.Zip;  
        tempLocation.state = response.State; 
        tempLocation.country = response.Country;   
  
        setLocation(tempLocation);
        setShowLocationInformationDialog(false);
    }

    const onLocationInforamtionCancel = () => {
        setShowLocationInformationDialog(false);
    }

    const onAddEditAddressConfirm = (res) => {
        setCurrentAddress(null);
        setCurrentAddressType(null);
        setShowAddAddressDialog(false);

        setLoadingAction(true);

        if (!havePermissionForAddress) {
            axios.post(`ShoppingService/api/v1/customerAdmin/location/${locationId}/address/notification`, res).then((response) => {
                setConfirmTitle('Location Pending');
                setConfirmMsg('Your shipping address request has been sent to our Account Maintenance Team who will notify you when the change is complete.');
                setLoadingAction(false);
                setShowUserInformDialog(true);
            });
        } else
            axios.post(`ShoppingService/api/v1/customerAdmin/location/${locationId}/address`, res).then((response) => {
                logEvent('Customer Admin Add Shipping Address', {Facility_Id: locationId, Shipping_Address_Id: res.id,Shipping_Address_Name: res.addressName});
                history.go(0); // reload rute
            });
    }

    const onAddEditAddressCancel = () => {
        setCurrentAddress(null);
        setCurrentAddressType(null);
        setShowAddAddressDialog(false);
    }

    const onUserInformConfirm = () => {
        setShowUserInformDialog(false);
    }

    const onEditAddressClick = (address) => {
        setCurrentAddress(address);
        setShowAddAddressDialog(true);
    }

    const onNewAddressClick = (type) => {
        setCurrentAddressType(type);
        setShowAddAddressDialog(true);
    }

    const onCarrierConfirm = (response) => {
        let tempLocation = {... location};

        tempLocation.fedexAccount = response.fedexAccount;
        tempLocation.upsAccount = response.upsAccount;
   
        setLocation(tempLocation);
        setShowCarrierAccountsDialog(false);
    }

    const onCarrierCancel = () => {
        setShowCarrierAccountsDialog(false);
    }

    return (
        <React.Fragment>
            {!loading && location ? (
                <div className="full-row on-site-main-content" style={{background: 'linear-gradient(0, #f1f1f0 40%, white 45%)'}}>
                    {loadingAction && <LoaderContainer>
                        <Loader />
                    </LoaderContainer>}
                    <div className="row on-site-default-page">
                        <div className="col-xs-12">
                            <header className="on-site-header">
                                <section>
                                    <PageMetaData
                                        title="Location Details"
                                        pageType="other"
                                        trackAnalytics={true}
                                    />

                                    <BreadCrumbs style={{marginTop: '0px'}} />
                                </section>

                                <section className="top-row">
                                    <div className="back-text" onClick= {() => history.goBack()}>
                                        <i
                                            className="fa fa-chevron-left"
                                            aria-hidden="true"
                                            style={{paddingRight: 10}}
                                        />
                                        Back to Admin
                                    </div>
                                </section>
                            </header>
                        </div>
                        <div className="col-xs-12">
                            <div className="service-detail" style={{padding: 0}}>
                                <aside className="col-md-4 col-sm-12 col-xs-12">
                                    <div className="col-xs-12 left-col">
                                        <div style={{padding: '10px 0'}}>
                                            <div style={{textAlign: 'center', padding: '20px', marginBottom: '10px'}}> 
                                                <span style={{fontSize: '24px', fontWeight: '600'}}>{location.name}</span> 
                                            </div>
                                            <div>Created: <span style={{fontWeight: '600'}}>{moment(location.createdDate).format('MM/DD/YYYY')}</span></div>
                                        </div>

                                    </div>
                                </aside>
                                <main className="col-md-8 col-sm-12 col-xs-12">
                                    <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div className="right-section-wrapper">
                                                <span className="right-section-title">LOCATION INFORMATION</span>
                                                <span className="edit-action" onClick={() => {
                                                    setShowLocationInformationDialog(true) 
                                                }} >Edit</span>
                                            </div> 
                                            <div className="right-section-item">
                                                <span>Name: </span><span className="right-section-title">{location.name}</span>  
                                            </div>  
                                            <div className="right-section-item">
                                                <span>Address: </span> {location.addressId && <span className="right-section-title">{location.address1} {location.city}, {location.state} {location.zip} {location.country}</span> } 
                                            </div>  
                                        </div>
                                    </div>
                                    <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div className="right-section-wrapper">
                                                <span className="right-section-title">SHIPPING ADDRESSES</span>
                                                <span className="edit-action" onClick={() => {
                                                    onNewAddressClick(6) 
                                                }} >Add Address</span>
                                            </div> 
                                            { location.shippingAddresses.length < 3 || showAllShippingAddresses ?
                                                location.shippingAddresses.map((x) => {
                                                    return (<div onClick={() => {
                                                        onEditAddressClick(x) 
                                                    }} className="right-section-item-link">
                                                        <span className="right-section-title">{x.address1}</span>  
                                                    </div>)
                                                })
                                                : location.shippingAddresses.slice(0, 3).map((x) => {
                                                    return (<div onClick={() => {
                                                        onEditAddressClick(x) 
                                                    }} className="right-section-item-link">
                                                        <span className="right-section-title">{x.address1}</span>  
                                                    </div>)
                                                })
                                            }
                                            {
                                                location.shippingAddresses.length > 3 && !showAllShippingAddresses ? <span onClick={() => {
                                                    setShowAllShippingAddresses(true); 
                                                }} className="show-more"> Show more ({location.shippingAddresses.length - 3}) </span> : null
                                            }
 
                                        </div>
                                    </div>
                                    <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div className="right-section-wrapper">
                                                <span className="right-section-title">BILLING ADDRESSES</span>
                                                <span className="edit-action" onClick={() => {
                                                    onNewAddressClick(2) 
                                                }} >Add Address</span>
                                            </div> 
                                            { location.billingAddresses.length < 3 || showAllBillingAddresses ?
                                                location.billingAddresses.map((x) => {
                                                    return (<div onClick={() => {
                                                        onEditAddressClick(x) 
                                                    }} className="right-section-item-link">
                                                        <span className="right-section-title">{x.address1}</span>  
                                                    </div>)
                                                })
                                                : location.billingAddresses.slice(0, 3).map((x) => {
                                                    return (<div onClick={() => {
                                                        onEditAddressClick(x) 
                                                    }} className="right-section-item-link">
                                                        <span className="right-section-title">{x.address1}</span>  
                                                    </div>)
                                                })
                                            }
                                            {
                                                location.billingAddresses.length > 3 && !showAllBillingAddresses ? <span onClick={() => {
                                                    setShowAllBillingAddresses(true); 
                                                }} className="show-more"> Show more ({location.billingAddresses.length - 3}) </span> : null
                                            }
 
                                        </div>
                                    </div>
                                    <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div className="right-section-wrapper">
                                                <span className="right-section-title">USERS</span>
                                            </div> 
                                            { location.users.length < 3 || showAllUsers ?
                                                location.users.map((x) => {
                                                    return (<div className="right-section-item">
                                                        <span className="right-section-title">{x.firstName} {x.lastName}</span>  
                                                    </div>)
                                                })
                                                : location.users.slice(0, 3).map((x) => {
                                                    return (<div className="right-section-item">
                                                        <span className="right-section-title">{x.firstName} {x.lastName}</span>  
                                                    </div>)
                                                })
                                            }
                                            {
                                                location.users.length > 3 && !showAllUsers ? <span onClick={() => {
                                                    setShowAllUsers(true); 
                                                }} className="show-more"> Show more ({location.users.length - 3}) </span> : null
                                            }
 
                                        </div>
                                    </div>
                                    <div className="col-xs-12 right-col" style={{marginBottom: '20px'}}>
                                        <div style={{padding: '10px 0'}}>
                                            <div className="right-section-wrapper">
                                                <span className="right-section-title">SHIPPING ACCOUNTS</span>
                                                <span className="edit-action" onClick={() => {
                                                    setShowCarrierAccountsDialog(true)
                                                }} >Edit</span>
                                            </div> 
                                            <div className="right-section-item">
                                                <span>Carrier: </span><span className="right-section-title">FedEx</span>  
                                            </div>  
                                            <div className="right-section-item">
                                                <span>Account#: </span><span className="right-section-title">{location.fedexAccount ? location.fedexAccount : 'N/A'}</span>  
                                            </div> 
                                            <div className="right-section-item">
                                                <span>Carrier: </span><span className="right-section-title">UPS</span>  
                                            </div>  
                                            <div className="right-section-item">
                                                <span>Account#: </span><span className="right-section-title">{location.upsAccount ? location.upsAccount : 'N/A'}</span>  
                                            </div> 
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                renderBusy()
            )}
            { showLocationInfromationDialog && <CustomerAdminChangeLocationInfoDialog onConfirm={onLocationInforamtionConfirm} onCancel={onLocationInforamtionCancel} location={location} /> } 
            { showAddAddressDialog && <CustomerAdminAddAddressDialog onConfirm={onAddEditAddressConfirm} onCancel={onAddEditAddressCancel} address={currentAddress} locationId={location.id} addressType={currentAddressType} /> } 
            { showCarrierAccountsDialog && <CustomerAdminAddCarrierDialog onConfirm={onCarrierConfirm} onCancel={onCarrierCancel} location={location} /> } 
            { showUserInformDialog && <CustomerAdminInformDialog onConfirm={onUserInformConfirm} title={confirmTitle} msg={confirmMsg} /> } 
        </React.Fragment>
    );
}

const renderBusy = () => {
    return (
        <div className="full-row load-quote">
            <span className="message">Retrieving location details...</span>
            <div className="loader" />
        </div>
    );
};
